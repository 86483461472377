.about {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	text-align: center;
	hyphens: auto;
	padding: 0;
	padding-top: 7rem;
	padding-bottom: 30px;
	color: var(--main-font-color);
}
.h1 {
	font-weight: 700;
	font-size: 3rem;
	padding-bottom: 20px;
	color: var(--main-font-color);
}
.h3 {
	color: var(--additional-color);
	font-size: 2rem;
}
.description {
	display: flex;
	flex-direction: column;
	width: 1000px;
	max-width: 80vw;
	margin: 3rem auto;
	text-align: left;
}
.p {
	font-size: 1.6rem;
	margin-bottom: 1.6rem;
	text-indent: 20px;
}
.more {
	max-width: 95vw;
	text-align: center;
}
.more-link {
	display: flex;
	cursor: pointer;
	text-decoration: none;
	color: var(--additional-color);
}
