.footer {
	width: 100%;
	background-color: var(--additional-color);
	margin-top: 50px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: auto;
	bottom: 0;
	visibility: visible;
	padding: 10px;
}
.footer2 {
	width: 100%;
	background-color: var(--additional-color);

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: auto;
	bottom: 0;
	visibility: visible;
	padding: 10px;
}

.footer .menuactive {
	visibility: hidden;
}

.footItem {
	font-size: 1.6rem;
	margin: 20px 40px;
}
.footLink {
	color: var(--main-header-color);
	text-decoration: none;
}

@media screen and (max-width: 950px) {
	.footer {
		flex-direction: column;
		height: auto;
	}
}
