.hp {
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	text-align: center;
	height: auto;
	min-height: 85vh;
}
.card {
	display: flex;
	flex-direction: column;
	justify-content: center;

	min-width: 70%;
}
.h1 {
	color: var(--main-font-color);
	font-size: 3.4rem;
	margin: 2rem;
}
.h3 {
	color: var(--additional-color);
	font-size: 1.8rem;
	margin: 1rem;
}

button.imgBorder,
button.imgBorder:hover,
button.imgBorder:focus {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 150px;
	border: 3px solid var(--additional-color);
	border-radius: 100%;
	margin: 50px auto;
	background: var(--main-header-color);
	outline: 0;
	box-shadow: none !important;
}
.imgHead {
	max-width: 150px;
	width: 100px;
}
.imageBrain {
	z-index: -1;
}
.more {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 75vw;
	margin: auto;
	margin-top: 150px;
	margin-bottom: 50px;
}
.link {
	text-decoration: none;
	cursor: pointer;
}
.more .item {
	display: flex;
	flex-direction: column;
	width: 23vw;
	min-height: 35vh;
	justify-content: space-between;
	text-align: center;
	padding-top: 20px;
	/* padding-bottom: 20px; */
	border: 1px solid var(--additional-color);
	color: var(--main-font-color);
}
.more .item h4 {
	font-size: 2rem;
}
.more .item .moreDesc {
	font-size: 1.6rem;
	padding: 10px;
}
.more .item .moreLink {
	display:block;
	width: 100%;
	color: var(--main-header-color);
	background-color: var(--additional-color);
	padding: 5px;
	font-size: 1.6rem;
	bottom:0;
}
/* background: -webkit-linear-gradient(top, #df2a2a, #080808, #8d4040, #080808, #aa2626);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; */
@media screen and (max-width: 830px) {
	.hp {
		padding-top: 4rem;
		justify-content: flex-start;
	}
	.h1 {
		margin: 1rem;
	}
	.imgBorder {
		margin: 30px auto;
	}
	.more {
		flex-direction: column;
		justify-content: space-between;
	}
	.more .item {
		margin-bottom: 15px;
		width: 100%;
	}
}
