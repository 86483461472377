@import url(https://fonts.googleapis.com/css?family=Poppins:400,700,900&display=swap);
body {
	/* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif; */
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
:root {
	--main-bg-color: #fff;
	--main-header-color: #fff;
	--main-font-color: #3b4652;
	/* --main-font-color: rgba(34, 33, 33); */
	--additional-color: #2b79c2;
	/* --additional-color: #ff4f79; */
}

.App {
	text-align: center;
	display: flex;
	flex-direction: column;
}

.App-logo {
	-webkit-animation: App-logo-spin infinite 20s linear;
	        animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

main {
	padding-top: 7rem;
	background: -webkit-gradient(linear, left top, left bottom, from(#b9bad336), to(#fff));
	background: -webkit-linear-gradient(#b9bad336, #fff);
	background: linear-gradient(#b9bad336, #fff);
}
@-webkit-keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}
@keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

/* :root {
	--main-bg-color: #fff;
	--main-header-color: #fff;
	--main-font-color: rgba(34, 33, 33);
	--additional-color: #ff4f79;
} */
/* :root {
	--main-bg-color: #06051e;
	--main-header-color: #222146;
	--main-font-color: #fff;
	--additional-color: #ff4f79;
} */

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-family: 'Roboto', sans-serif;
	font-size: 10px;
	box-sizing: border-box;
}

body {
	width: 100%;
	/* height: 100vh; */
	background-color: var(--main-bg-color);
}

header {
	position: fixed;
	top: 0;
	width: 100vw;
	background-color: var(--main-header-color);
	border-bottom: 1px solid var(--additional-color);
}
.container {
	position: relative;
	width: 100%;
	max-width: 1050px;
	margin: 0 auto;
	padding: 0 1.5rem;
}

nav {
	width: 100%;
	height: 8rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.navbar-brand {
	/* background-color: var(--additional-color); */
	border: 2px solid var(--additional-color);
	width: 6rem;
	height: 6rem;
	border-radius: 100%;
	display: flex;
	-webkit-transform: scale(1);
	        transform: scale(1);
	-webkit-transition: all 1s;
	transition: all 1s;
	margin: auto 20px auto 0;
}
.navbar-brand.active {
	-webkit-transform: scale(1);
	        transform: scale(1);
}
.navbar-brand i {
	font-size: 4rem;
	color: var(--main-font-color);
	margin: auto;
}
.navbar-brand .imgHead {
	font-size: 4rem;
	color: var(--main-font-color);
	margin: auto;
	max-width: 150px;
	width: 4rem;
}
.nav-title {
	display: block;
	left: 0;
	color: var(--main-font-color);
	font-size: 3rem;
	/* margin: auto 10px; */
	-webkit-transform: scale(1);
	        transform: scale(1);
	-webkit-transition: all 1s;
	transition: all 1s;
	width: 35vw;
}
.nav-title.active {
	-webkit-transform: scale(0);
	        transform: scale(0);
}
.nav-title span {
	color: var(--additional-color);
}
.nav-list {
	display: flex;
	list-style: none;
	width: 35vw;
	justify-content: flex-end;
}
.nav-link {
	text-decoration: none;
	display: block;
	margin: 16px 2rem 0 2rem;
	/* margin-top: 16px; */
	color: var(--main-font-color);
	-webkit-transform: scale(0);
	        transform: scale(0);
	-webkit-transition: -webkit-transform 650ms;
	transition: -webkit-transform 650ms;
	transition: transform 650ms;
	transition: transform 650ms, -webkit-transform 650ms;
}
.nav-item {
	position: relative;
	line-height: 8rem;
	font-size: 1.7rem;
	-webkit-transition-delay: .5s;
	        transition-delay: .5s;
	visibility: hidden;
	/* background: red; */
	margin-top: 0;
}
.nav-item::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 2px;
	background-color: var(--additional-color);
	left: 0;
	bottom: 0;
	-webkit-transform: scaleX(0);
	        transform: scaleX(0);
	-webkit-transform-origin: right;
	        transform-origin: right;
	-webkit-transition: -webkit-transform 650ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
	transition: -webkit-transform 650ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
	transition: transform 650ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
	transition: transform 650ms cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 650ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.nav-item:hover::before {
	-webkit-transform: scaleX(1);
	        transform: scaleX(1);
	-webkit-transform-origin: left;
	        transform-origin: left;
}

.hamburger {
	width: 3.6rem;
	height: 1.8rem;
	position: relative;
	cursor: pointer;
	line-height: 5rem;
	z-index: 1500;
}

.hamburger .line {
	width: 100%;
	height: 2px;
	background-color: var(--main-font-color);
	position: absolute;
	display: block;
	right: 10px;
	-webkit-transition: all 650ms;
	transition: all 650ms;
}
.hamburger .line:nth-child(1) {
	top: 0;
}
.hamburger .line:nth-child(2) {
	top: 50%;
	margin-top: -1px;
	width: 2.8rem;
}
.hamburger .line:nth-child(3) {
	top: 100%;
	margin-top: -2px;
	width: 2rem;
}
.hamburger.active .line:nth-child(1) {
	-webkit-transform: translateY(.8rem) rotate(45deg);
	        transform: translateY(.8rem) rotate(45deg);
	width: 2rem;
}
.hamburger.active .line:nth-child(2) {
	opacity: 0;
}
.hamburger.active .line:nth-child(3) {
	-webkit-transform: translateY(-.8rem) rotate(-45deg);
	        transform: translateY(-.8rem) rotate(-45deg);
	width: 2rem;
}

.nav-list.active .nav-link {
	-webkit-transform: scale(1);
	        transform: scale(1);
}

.nav-list.active .nav-item {
	-webkit-transition-delay: 0s;
	        transition-delay: 0s;
	-webkit-transition-delay: initial;
	        transition-delay: initial;
	visibility: visible;
}
.nav-list.active .nav-item:nth-child(1) .nav-link {
	-webkit-transition-delay: .5s;
	        transition-delay: .5s;
}
.nav-list.active .nav-item:nth-child(2) .nav-link {
	-webkit-transition-delay: .4s;
	        transition-delay: .4s;
}
.nav-list.active .nav-item:nth-child(3) .nav-link {
	-webkit-transition-delay: .3s;
	        transition-delay: .3s;
}
.nav-list.active .nav-item:nth-child(4) .nav-link {
	-webkit-transition-delay: .2s;
	        transition-delay: .2s;
}
.nav-list.active .nav-item:nth-child(5) .nav-link {
	-webkit-transition-delay: .1s;
	        transition-delay: .1s;
}
/*reverse*/
.nav-list .nav-item:nth-child(1) .nav-link {
	-webkit-transition-delay: .1s;
	        transition-delay: .1s;
}
.nav-list .nav-item:nth-child(2) .nav-link {
	-webkit-transition-delay: .2s;
	        transition-delay: .2s;
}
.nav-list .nav-item:nth-child(3) .nav-link {
	-webkit-transition-delay: .3s;
	        transition-delay: .3s;
}
.nav-list .nav-item:nth-child(4) .nav-link {
	-webkit-transition-delay: .4s;
	        transition-delay: .4s;
}
.nav-list .nav-item:nth-child(5) .nav-link {
	-webkit-transition-delay: .5s;
	        transition-delay: .5s;
}

@media screen and (max-width: 950px) {
	.nav-list {
		width: 100%;
		height: 100vh;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: var(--main-header-color);
		position: fixed;
		left: 0;
		bottom: -100vh;
		-webkit-transition: bottom 650ms;
		transition: bottom 650ms;

		margin: 0;
	}
	.nav-list.active {
		bottom: 0;
	}
	.navbar-brand {
		border: 2px solid var(--additional-color);
		/* background-color: var(--additional-color); */
		width: 7vw;
		height: 7vw;
		border-radius: 100%;
		display: flex;
	}
	.navbar-brand.active {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
	.navbar-brand i {
		font-size: 4vw;
		color: var(--main-font-color);
		/* margin: auto; */
	}
	.navbar-brand .imgHead {
		width: 4vw;
	}
	.nav-title {
		font-size: 4vw;
		width: 65vw;
	}
	.nav-item:hover::before {
		-webkit-transform: scaleX(0);
		        transform: scaleX(0);
		-webkit-transform-origin: left;
		        transform-origin: left;
	}
	.nav-link {
		font-size: 2.4rem;
	}
}
@media screen and (max-width: 650px) {
	.nav-title {
		font-size: 5vw;
		width: 65vw;
	}
}

.home_hp__rDg-n {
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	text-align: center;
	height: auto;
	min-height: 85vh;
}
.home_card__3-J2E {
	display: flex;
	flex-direction: column;
	justify-content: center;

	min-width: 70%;
}
.home_h1__2K-6Q {
	color: var(--main-font-color);
	font-size: 3.4rem;
	margin: 2rem;
}
.home_h3__3ZMk6 {
	color: var(--additional-color);
	font-size: 1.8rem;
	margin: 1rem;
}

button.home_imgBorder__1vwJe,
button.home_imgBorder__1vwJe:hover,
button.home_imgBorder__1vwJe:focus {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 150px;
	border: 3px solid var(--additional-color);
	border-radius: 100%;
	margin: 50px auto;
	background: var(--main-header-color);
	outline: 0;
	box-shadow: none !important;
}
.home_imgHead__3WzkV {
	max-width: 150px;
	width: 100px;
}
.home_imageBrain__eUHQs {
	z-index: -1;
}
.home_more__487U- {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 75vw;
	margin: auto;
	margin-top: 150px;
	margin-bottom: 50px;
}
.home_link__N4PBN {
	text-decoration: none;
	cursor: pointer;
}
.home_more__487U- .home_item__2CZrm {
	display: flex;
	flex-direction: column;
	width: 23vw;
	min-height: 35vh;
	justify-content: space-between;
	text-align: center;
	padding-top: 20px;
	/* padding-bottom: 20px; */
	border: 1px solid var(--additional-color);
	color: var(--main-font-color);
}
.home_more__487U- .home_item__2CZrm h4 {
	font-size: 2rem;
}
.home_more__487U- .home_item__2CZrm .home_moreDesc__2eV7J {
	font-size: 1.6rem;
	padding: 10px;
}
.home_more__487U- .home_item__2CZrm .home_moreLink__Djglc {
	display:block;
	width: 100%;
	color: var(--main-header-color);
	background-color: var(--additional-color);
	padding: 5px;
	font-size: 1.6rem;
	bottom:0;
}
/* background: -webkit-linear-gradient(top, #df2a2a, #080808, #8d4040, #080808, #aa2626);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; */
@media screen and (max-width: 830px) {
	.home_hp__rDg-n {
		padding-top: 4rem;
		justify-content: flex-start;
	}
	.home_h1__2K-6Q {
		margin: 1rem;
	}
	.home_imgBorder__1vwJe {
		margin: 30px auto;
	}
	.home_more__487U- {
		flex-direction: column;
		justify-content: space-between;
	}
	.home_more__487U- .home_item__2CZrm {
		margin-bottom: 15px;
		width: 100%;
	}
}

.o-mnie_about__1eorp {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	text-align: center;
	-webkit-hyphens: auto;
	    -ms-hyphens: auto;
	        hyphens: auto;
	padding: 0;
	padding-top: 7rem;
	padding-bottom: 30px;
	color: var(--main-font-color);
}
.o-mnie_h1__33u14 {
	font-weight: 700;
	font-size: 3rem;
	padding-bottom: 20px;
	color: var(--main-font-color);
}
.o-mnie_h3__1OxlH {
	color: var(--additional-color);
	font-size: 2rem;
}
.o-mnie_description__16WVW {
	display: flex;
	flex-direction: column;
	width: 1000px;
	max-width: 80vw;
	margin: 3rem auto;
	text-align: left;
}
.o-mnie_p__1JmK_ {
	font-size: 1.6rem;
	margin-bottom: 1.6rem;
	text-indent: 20px;
}
.o-mnie_more__Bp4u5 {
	max-width: 95vw;
	text-align: center;
}
.o-mnie_more-link__3_Nos {
	display: flex;
	cursor: pointer;
	text-decoration: none;
	color: var(--additional-color);
}

.zakres-uslug_services__1ut2Z {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	text-align: center;
	-webkit-hyphens: auto;
	    -ms-hyphens: auto;
	        hyphens: auto;
	padding: 0;
	padding-top: 7rem;
	padding-bottom: 30px;
	color: var(--main-font-color);
}
.zakres-uslug_h1__2gm_M {
	font-weight: 700;
	font-size: 3rem;
	padding-bottom: 20px;
	color: var(--main-font-color);
	min-height: 10vh;
}
.zakres-uslug_h3__BLHnM {
	color: var(--additional-color);
	font-size: 2rem;
}
.zakres-uslug_description__1Ofan {
	display: flex;
	flex-direction: column;
	width: 1000px;
	max-width: 80vw;
	margin: 3rem auto;
	text-align: left;
}
.zakres-uslug_p__vfZwH {
	color: var(--additional-color);
	font-weight: 600;
}
.zakres-uslug_p__vfZwH,
ul {
	font-size: 1.6rem;
	margin-bottom: 1.6rem;
}
ul {
	color: var(--main-font-color);
	margin-left: 1.6rem;
	list-style: circle;
}

.kontakt_contact__3jx_y {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	text-align: center;
	-webkit-hyphens: auto;
	    -ms-hyphens: auto;
	        hyphens: auto;
	padding: 0;
	padding-top: 7rem;
	padding-bottom: 30px;
	color: var(--main-font-color);
}
.kontakt_h1__1YOGj {
	font-weight: 700;
	font-size: 3rem;
	padding-bottom: 20px;
	color: var(--main-font-color);
	min-height: 10rem;
}
.kontakt_h3__3ynKt {
	font-size: 2rem;
}
.kontakt_description__14535 {
	display: flex;
	flex-direction: column;
	width: 1000px;
	max-width: 75vw;
	margin: 0 auto;
	text-align: left;
}
.kontakt_p__u-VAA {
	color: var(--additional-color);
	font-size: 1.9rem;
	font-weight: 600;
	margin-bottom: 1.6rem;
}

.kontakt_gabinet__lm0Ew {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	align-content: center;
	align-items: center;
	margin: 20px auto;
	width: 75vw;
	min-height: 30vh;
	height: auto;
	border: 1px solid rgba(146, 145, 145, 0.63);
	padding: 5px;
	background-color: #fff;
}

img.kontakt_gabinetLogo__6UIdh {
	display: block;
	width: 400px;
	margin: auto;
}
.kontakt_gabinetDescription__OxT9M {
	display: flex;
	flex-direction: column;
	width: 80vw;
	justify-content: space-around;
	margin-top: 5vh;
	/* margin-bottom: 5vh; */
	font-size: 2rem;

	font-weight: 600;
}

.kontakt_gabinetDescription__OxT9M h2 {
	display: none;
	font-size: 2.6rem;
	color: var(--main-font-color);
}

.kontakt_gabinetDescription__OxT9M .kontakt_tel__PhuVv {
	/* border: 2px solid var(--additional-color); */
	padding: 10px;
	/* color: var(--main-font-color); */
	color: var(--additional-color);
	font-weight: 700;
	font-size: 2.6rem;
	text-decoration: none;
	cursor: pointer;
	margin: 15px auto;
	border-radius: 2.4rem;
}
.kontakt_gabinetDescription__OxT9M .kontakt_tel__PhuVv span {
	color: var(--main-font-color);
	font-size: 1.6rem;
	font-weight: 600;
	border-bottom: 1px solid var(--additional-color);
}

.kontakt_more__3LxyQ {
	max-width: 95vw;
	text-align: center;
}
.kontakt_more-link__1J4xh {
	display: flex;
	cursor: pointer;
	text-decoration: none;
	color: var(--additional-color);
}
@media screen and (max-width: 830px) {
	.kontakt_gabinet__lm0Ew {
		flex-direction: column;
		max-width: 85vw;
	}
	.kontakt_gabinetLogo__6UIdh {
		max-width: 50vw;
		margin-top: 50%-4vw;
	}
	.kontakt_gabinetDescription__OxT9M {
		flex-direction: column;
	}
}

.notmatch_notmatch__3u5lh {
	width: 65vw;
	margin: auto;
	margin-top: 150px;
	text-align: center;
}
.notmatch_h1__3XSt5 {
	color: var(--additional-color);
	font-size: 3.6rem;
}
.notmatch_h2__25A8T {
	margin-top: 50px;
	font-size: 1.8rem;
	color: var(--main-font-color);
}
.notmatch_link__1gVLp {
	color: var(--additional-color);
	text-decoration: none;
	cursor: pointer;
	font-size: 2.2rem;
	font-weight: 700;
}

.footer_footer__2oW47 {
	width: 100%;
	background-color: var(--additional-color);
	margin-top: 50px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: auto;
	bottom: 0;
	visibility: visible;
	padding: 10px;
}
.footer_footer2__2E9Jt {
	width: 100%;
	background-color: var(--additional-color);

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: auto;
	bottom: 0;
	visibility: visible;
	padding: 10px;
}

.footer_footer__2oW47 .footer_menuactive__1ypva {
	visibility: hidden;
}

.footer_footItem__11x8O {
	font-size: 1.6rem;
	margin: 20px 40px;
}
.footer_footLink__37whd {
	color: var(--main-header-color);
	text-decoration: none;
}

@media screen and (max-width: 950px) {
	.footer_footer__2oW47 {
		flex-direction: column;
		height: auto;
	}
}

