.notmatch {
	width: 65vw;
	margin: auto;
	margin-top: 150px;
	text-align: center;
}
.h1 {
	color: var(--additional-color);
	font-size: 3.6rem;
}
.h2 {
	margin-top: 50px;
	font-size: 1.8rem;
	color: var(--main-font-color);
}
.link {
	color: var(--additional-color);
	text-decoration: none;
	cursor: pointer;
	font-size: 2.2rem;
	font-weight: 700;
}
